import Idto from "interfaces/idto.interface";

import { Status } from "tools/types/status";
import { SelectedProductFilterDto } from "./productfilter.dto";
import { OfferFilterDto } from "./offerfilter.dto";

export class OfferDto implements Idto {
  id?: number | string;
  idproduct?: number;
  priority?: number;

  minq?: number;
  maxq?: number;

  price?: number | string;
  discount_percent?: number | string;
  discount_value?: number | string;
  real_price?: number | string;

  startdate?: number;
  enddate?: number;

  status?: number;

  offerFilters?: OfferFilterDto[];

  constructor(
    id?: number | string,
    idproduct?: number,
    priority?: number,
    minq?: number,
    maxq?: number,
    price?: number,
    discount_percent?: number,
    discount_value?: number,
    real_price?: number,
    startdate?: number,
    enddate?: number,
    status?: number
  ) {
    this.id = id || 0;
    this.idproduct = idproduct || 0;
    this.priority = priority || 0;

    this.minq = minq || 0;
    this.maxq = maxq || 0;

    this.price = price || 0;
    this.discount_percent = discount_percent || 0;
    this.discount_value = discount_value || 0;
    this.real_price = real_price || 0;

    this.startdate = startdate || 0;
    this.enddate = enddate || 0;

    this.status = status || Status.ACTIVE;
  }

  static getOfferByQuantityAndFilters(
    quantity: number,
    filters: SelectedProductFilterDto[],
    offers: OfferDto[]
  ): OfferDto | null {
    if (!quantity) quantity = 1;
    if (typeof quantity === "string") quantity = parseInt(quantity);
    if (!offers) return null;
    if (!offers.length) return null;
    let newOffers = offers
      .filter((item) => {
        if (item.minq && item.maxq) {
          return item.minq <= quantity && item.maxq >= quantity;
        } else if (item.minq) {
          return item.minq <= quantity;
        } else if (item.maxq) {
          return item.maxq >= quantity;
        }
        return false;
      })
      .sort(sortCriteria);
    
    if (!newOffers) return null;
    if (!newOffers.length) return null;
    if (!filters) return newOffers[0];
    if (!filters.length) return newOffers[0];
    
    let result: OfferDto[] = [];

    for (const item of newOffers) {
      if (!item.offerFilters || !item.offerFilters.length) {
        result.push(item);
        continue;
      }

      for (const filter of item.offerFilters) {
        for (const itemFilter of filters) {
          if (
            (itemFilter.idfilter == filter.idfilter || filter.idfilter == -1) &&
            (itemFilter.idfilterdictionar == filter.idfilterdictionar ||
              filter.idfilterdictionar == -1)
          ) {
            result.push(item);
          }
        }
      }
    }
    result = result.sort(sortCriteria);
    if (result.length) return result[0];

    return null;
  }
}

const sortCriteria = (a: OfferDto, b: OfferDto) => {
  if (a.priority && b.priority) {
    return a.priority - b.priority;
  }
  return 0;
};

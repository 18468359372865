import { Box, Skeleton, Typography, useTheme } from "@mui/material";
import { LocationDto } from "dto/static/location.dto";
import { useLabel } from "hooks/useLabel";
import useObject from "hooks/useObject";
import { CallbackType } from "interfaces/commontypes.interface";
import React from "react";
import { LocationService } from "services/static/location.service";
import { CommonTools } from "tools/utils/common.tool";

type Props = {
  id: number;
};
const service = new LocationService();
const DisplayDefaultLocation: React.FC<Props> = ({ id }) => {
  const { LL } = useLabel();
  const theme = useTheme();

  const get = (id: string, cb?: CallbackType, cbParams?: any) => {
    if (!id) return;
    if (id.toString() === "0") return;
    service.get(id, cb, cbParams);
  };
  const [loading, object] = useObject<LocationDto>(get, id.toString(), [id]);
  if (!id) return null;
  if (loading) return <Skeleton />;
  if (!object) return null;

  return (
    <Box>
      <Typography
        sx={{
          fontWeight: "800",
          fontSize: "13px",
          color: theme.palette.primary.main,
          textTransform: "uppercase",
          marginTop: "-25px",
          marginBottom: "10px",
        }}>
        {LL("field_location")}
      </Typography>
      <Typography>
        {CommonTools.processObjectField(object, ["_name"])}
      </Typography>
    </Box>
  );
};

export { DisplayDefaultLocation };

import { ProductFilterDto } from "dto/product/productfilter.dto";
import Idto from "interfaces/idto.interface";

import { CartDto } from "./cart.dto";
import { UserDto } from "dto/user/user.dto";
import { ClientType } from "tools/types/clienttype";
import { PayMethodType } from "tools/types/paymethod";

export class CheckoutDto implements Idto {
  paymethod: number;
  coments: string;
  destinatar_idcountry: number;
  destinatar_idlocation: number;
  destinatar_address: string;
  client_id: number;
  client_name: string;
  client_mobil: string;
  client_email: string;
  client_othercontacts: string;
  client_type: number;
  client_fct_fiz_name: string;
  client_fct_fiz_idno: string;
  client_fct_fiz_docnumber: string;
  client_fct_fiz_address: string;
  client_fct_fiz_phone: string;
  client_fct_jur_company: string;
  client_fct_jur_idno: string;
  client_fct_jur_othercode: string;
  client_fct_jur_address: string;
  client_fct_jur_iban: string;
  client_fct_jur_bank: string;
  client_fct_jur_phone: string;
  iddeliverymethod: number;
  delivery_comments: string;
  orderProducts: Array<OrderProducts>;

  constructor(
    paymethod?: number,
    coments?: string,
    destinatar_idcountry?: number,
    destinatar_idlocation?: number,
    destinatar_address?: string,
    client_id?: number,
    client_name?: string,
    client_mobil?: string,
    client_email?: string,
    client_othercontacts?: string,
    client_type?: number,
    client_fct_fiz_name?: string,
    client_fct_fiz_idno?: string,
    client_fct_fiz_docnumber?: string,
    client_fct_fiz_address?: string,
    client_fct_fiz_phone?: string,
    client_fct_jur_company?: string,
    client_fct_jur_idno?: string,
    client_fct_jur_othercode?: string,
    client_fct_jur_address?: string,
    client_fct_jur_iban?: string,
    client_fct_jur_bank?: string,
    client_fct_jur_phone?: string,
    iddeliverymethod?: number,
    delivery_comments?: string,
    orderProducts?: Array<OrderProducts>
  ) {
    this.paymethod = paymethod || PayMethodType.CARD;
    this.coments = coments || "";
    this.destinatar_idcountry = destinatar_idcountry || 0;
    this.destinatar_idlocation = destinatar_idlocation || 0;
    this.destinatar_address = destinatar_address || "";

    this.client_id = client_id || 0;
    this.client_name = client_name || "";
    this.client_mobil = client_mobil || "";
    this.client_email = client_email || "";
    this.client_othercontacts = client_othercontacts || "";
    this.client_type = client_type || ClientType.INDIVIDUAL;

    this.client_fct_fiz_name = client_fct_fiz_name || "";
    this.client_fct_fiz_idno = client_fct_fiz_idno || "";
    this.client_fct_fiz_docnumber = client_fct_fiz_docnumber || "";
    this.client_fct_fiz_address = client_fct_fiz_address || "";
    this.client_fct_fiz_phone = client_fct_fiz_phone || "";
    this.client_fct_jur_company = client_fct_jur_company || "";

    this.client_fct_jur_idno = client_fct_jur_idno || "";
    this.client_fct_jur_othercode = client_fct_jur_othercode || "";
    this.client_fct_jur_address = client_fct_jur_address || "";
    this.client_fct_jur_iban = client_fct_jur_iban || "";
    this.client_fct_jur_bank = client_fct_jur_bank || "";
    this.client_fct_jur_phone = client_fct_jur_phone || "";

    this.iddeliverymethod = iddeliverymethod || 0;
    this.delivery_comments = delivery_comments || "";
    this.orderProducts = orderProducts || [];
  }

  static prepareSetDefaultValues(
    cart: CartDto[],
    user: UserDto | null,
    idCountry: number | string,
    idLocality: number | undefined
  ): CheckoutDto {
    let idUser = undefined;
    let clientName = undefined;
    let clientMobil = undefined;
    let clientEmail = undefined;
    const countryId = idCountry ? parseInt(idCountry.toString()) : 0;
    if (user) {
      idUser = user.id || 0;
      clientEmail = user.email || "";
      clientName = UserDto.getFullName(user);
    }

    return new CheckoutDto(
      undefined,
      undefined,
      countryId,
      idLocality,
      undefined,
      idUser,
      clientName,
      clientMobil,
      clientEmail,
      undefined,
      undefined,
      clientName,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      OrderProducts.fromArray(cart)
    );
  }
}

export class OrderProducts implements Idto {
  idproduct: number;
  quantity: number;
  orderProductFilters: Array<OrderProductFilters>;

  constructor(
    idproduct?: number,
    quantity?: number,
    orderProductFilters?: Array<OrderProductFilters>
  ) {
    this.idproduct = idproduct || 0;
    this.quantity = quantity || 0;
    this.orderProductFilters = orderProductFilters || [];
  }

  static orderProductsFromCartDto(cartDto: CartDto): OrderProducts {
    if (!cartDto || !cartDto.product || !cartDto.quantity || !cartDto.filters)
      return new OrderProducts();
    const product = cartDto.product;
    if (!product.id) return new OrderProducts();
    return new OrderProducts(
      parseInt(product.id.toString()),
      cartDto.quantity,
      OrderProductFilters.fromArray(cartDto.filters)
    );
  }

  static fromArray(arr: Array<CartDto>): Array<OrderProducts> {
    if (!arr || !arr.length) return [];
    const rez: Array<OrderProducts> = [];
    arr.forEach((obj) => {
      rez.push(OrderProducts.orderProductsFromCartDto(obj));
    });
    return rez;
  }
}

export class OrderProductFilters implements Idto {
  idfilter: number;
  idfilterdictionar: number;

  constructor(idfilter?: number, idfilterdictionar?: number) {
    this.idfilter = idfilter || 0;
    this.idfilterdictionar = idfilterdictionar || 0;
  }

  static orderProductFiltersFromProductFilters(
    obj: ProductFilterDto
  ): OrderProductFilters {
    if (!obj || !obj.idfilter || !obj.idfilterdictionar)
      return new OrderProductFilters();
    return new OrderProductFilters(obj.idfilter, obj.idfilterdictionar);
  }

  static fromArray(arr: Array<ProductFilterDto>): Array<OrderProductFilters> {
    if (!arr || !arr.length) return [];
    const rez: Array<OrderProductFilters> = [];
    arr.forEach((obj) => {
      rez.push(OrderProductFilters.orderProductFiltersFromProductFilters(obj));
    });
    return rez;
  }
}

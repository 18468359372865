import { MyButton } from "components/elements/button/MyButton";
import { ButtonWithLoading } from "components/elements/button/ButtonWithLoading";

import { ProductDto } from "dto/product/product.dto";
import { useCart } from "hooks/useCart";
import { useLabel } from "hooks/useLabel";
import { useProduct } from "hooks/useProduct";
import React, { useState } from "react";
import { Box, Typography } from "@mui/material";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";

type Props = {
  object: ProductDto;
};

const ButtonAddToCart: React.FC<Props> = ({ object }) => {
  const { LL } = useLabel();
  const [loading, setLoading] = useState(false);
  const { addFromCartProductPage } = useCart();
  const { selectedOffer, quantity, selectedFilters } = useProduct();
  const onClick = () => {
    if (!object || !quantity || !selectedOffer) return;
    let quantitySelected = 0;
    if (typeof quantity === "string") quantitySelected = parseInt(quantity);
    else quantitySelected = quantity;
    if (!quantitySelected) return;

    addFromCartProductPage(
      object,
      quantitySelected,
      selectedOffer,
      selectedFilters
    );
  };
  if (!object) return null;

  const isDisabled = !selectedOffer;
  return (
    // <MyButton disabled={isDisabled} cb={onClick}>
    //   {LL("add_to_cart")}
    // </MyButton>
    <Box mt={3} sx={{ width: "100%", display: "flex", justifyContent: "end" }}>
      <Box
        sx={{ width: { xs: "100%" }, height: "45px" }}
        className={`whiteButton listItemButton ${
          isDisabled ? "disabled" : ""
        } ${loading ? "loadingBtn" : ""}
            `}>
        <ButtonWithLoading
          type="submit"
          disabled={isDisabled}
          loading={loading}
          onClick={onClick}
          maxWidth="auto"
          sx={{
            height: "100%",
            // background: "red",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
            width: "100%",
          }}>
          <Typography
            sx={{ mt: "4px", ml: { xs: 0, sm: 3 }, fontWeight: "600" }}>
            {LL("add_to_cart")}
          </Typography>
          <Box
            sx={{
              width: "20px",
              display: { xs: "none", sm: "flex" },
              justifyContent: "center",
              alignItems: "center",
            }}>
            {!isDisabled && <KeyboardArrowRightIcon className="iconSvg3" />}
          </Box>
        </ButtonWithLoading>
      </Box>
    </Box>
  );
};

export { ButtonAddToCart };

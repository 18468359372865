import React from "react";
import { IPage } from "interfaces/page.interface";

import { Box, Divider, Typography } from "@mui/material";
import { useLabel } from "hooks/useLabel";
import { PaymentBlock } from "components/sale/paymentblock/PaymentBlock";
import { CartBlock } from "components/sale/cartblock/CartBlock";
import { useResponsive } from "hooks/useResponsive";
import { MyBreadcrumbs } from "components/elements/breadcrumb/MyBreadcrumbs";
import { BreadcrumbDto } from "dto/app/breadcrumb.dto";
import { CheckoutImage } from "components/sale/paymentblock/CheckoutImage";
import { Config } from "tools/utils/config";

const CheckoutPage: React.FC<IPage> = () => {
  const { LL } = useLabel();
  const { matchesCustom } = useResponsive();

  return (
    <Box
      sx={{
        position: "relative",
        overflow: "auto",
        mt: "-118px",
        maxWidth: "1920px",
        marginX: "auto",
        minHeight: `calc(100vh - ${Config.HEADER_HEIGHT}px - ${Config.FOOTER_HEIGHT}px + 118px)`,
      }}>
      <Box
        sx={{
          position: "relative",
          top: "-118px",
          left: 0,
          width: "100%",
          height: "118px",
          background: "white",
          zIndex: 0,
        }}
      />
      <Box
        sx={{
          position: "fixed",
          width: "100%",
          height: { xs: "50vh", md: "70vh" },
          minHeight: "400px",
          zIndex: -1,
          display: "flex",
          justifyContent: "center",
          overflow: "hidden",
          backgroundRepeat: "no-repeat",
        }}>
        <Box
          sx={{
            minWidth: "900px",
            width: "100%",
            height: "100%",
            objectFit: "cover",
            backgroundPosition: "center",
          }}>
          <CheckoutImage />
          <Box
            sx={{
              position: "absolute",
              width: "100%",
              height: "100%",
              maxWidth: "1920px",
              top: 0,
              left: 0,
              backgroundColor: "rgba(0, 0, 0, 0.5)",
            }}
          />
        </Box>
      </Box>
      <Box
        className="breadcrumbBox"
        sx={{
          width: "auto",
          px: { xs: 4, sm: 6, md: 10 },
          py: 3,
          background: "white",
          position: "relative",
          "::before": {
            content: '""',
            position: "absolute",
            top: "-180px",
            left: 0,
            height: "180px",
            width: "100%",
            background: "white",
          },
        }}>
        <MyBreadcrumbs objects={[new BreadcrumbDto(LL("checkout"))]} />
      </Box>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          height: { xs: "250px", md: "400px" },
          position: "relative",
          zIndex: 1,
        }}>
        <Typography
          variant="h1"
          sx={{
            py: 2,
            px: { xs: 4, sm: 6, md: 10, color: "white", textAlign: "center" },
          }}>
          {LL("checkout")}
        </Typography>
      </Box>

      <Box
        sx={{
          background: "white",
          py: 4,
          px: { xs: 4, sm: 6, md: 10 },
          width: "auto",
          display: "flex",
          justifyContent: "center",
          alignItems: "flex-start",
          minHeight: "calc(100vh - 964px)",
          // flexDirection: { xs: "column-reverse", lg: "row" },
          flexDirection: matchesCustom ? "row" : "column-reverse",
        }}>
        <Box sx={{ height: "100%", width: matchesCustom ? "50%" : "100%" }}>
          <Typography
            sx={{
              px: 3,
              py: 2,
              fontSize: "22px",
              fontWeight: "bold",
              textAlign: "center",
            }}>
            {LL("payemntInfo")}
          </Typography>
          <Box sx={{ px: 10 }}>
            <Divider
              sx={{
                backgroundColor: "rgba(0, 0, 0, 0.1)",
                width: "auto",
                px: 10,
              }}
            />
          </Box>
          <PaymentBlock />
        </Box>
        <Box sx={{ height: "100%", width: matchesCustom ? "50%" : "100%" }}>
          <Typography
            sx={{
              px: 3,
              py: 2,
              fontSize: "22px",
              fontWeight: "bold",
              textAlign: "center",
            }}>
            {LL("cartItems")}
          </Typography>
          <Box sx={{ px: 10 }}>
            <Divider
              sx={{
                backgroundColor: "rgba(0, 0, 0, 0.1)",
                width: "auto",
                px: 10,
              }}
            />
          </Box>
          <CartBlock />
        </Box>
      </Box>
    </Box>
  );
};

export { CheckoutPage };
